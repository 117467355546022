<script setup lang="ts">
import { ProductPlanSubscriptionTypes, type ProductCode, type ProductPlan } from '~/config'

export interface StporeProductPlan {
  plan: ProductPlan
  productCode: ProductCode
}

defineProps<StporeProductPlan>()

const content = ref<HTMLElement>()
const showFullContent = ref(true)
const containerHeight = computed(() => content.value?.clientHeight)
const isContainerTooBig = computed(() => (containerHeight.value ?? 0) > 390)
const shrink = computed(() => !showFullContent.value && isContainerTooBig.value)

watch(
  () => isContainerTooBig.value,
  (n) => {
    if (n) {
      showFullContent.value = false
    }
  }
)
</script>

<template>
  <CardComponent bg="ligth">
    <template #header>
      <div class="py-2 text-2xl font-bold">{{ plan.name }}</div>
    </template>

    <div class="grid gap-6 p-4">
      <div ref="content" class="grid gap-6">
        <div class="text-center">
          <span class="uppercase">Desde</span>
          <CoveragePrice :price="plan.price" :periodicity="plan.subscription" />
        </div>
        <PvDivider />
        <div class="grid gap-3 overflow-hidden" :class="{ 'h-60': shrink }">
          <div class="grid gap-2">
            <template v-for="item in plan.highlights.description.filter((i) => i.type === 'NOTE')">
              <div class="rounded-md border border-surface-50 py-2 text-center text-xs">
                <span>{{ item.label }}</span>
              </div>
            </template>
          </div>
          <span v-if="plan.highlights.title" class="text-xl font-bold">
            {{ plan.highlights.title }}:
          </span>
          <div class="grid gap-2">
            <template
              v-for="item in plan.highlights.description.filter(
                (i) => !i.type || i.type === 'ITEM'
              )"
            >
              <div class="class gap-2 text-sm">
                <div>{{ item.label }}</div>
                <div class="font-bold">{{ item.detail }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div
        v-if="isContainerTooBig"
        class="flex items-center text-sm before:me-3 before:flex-1 before:border-t before:border-primary-50/10 after:ms-3 after:flex-1 after:border-t after:border-primary-50/10"
      >
        <PvButton
          label="ver más"
          outlined
          severity="contrast"
          @click="() => (showFullContent = !showFullContent)"
        />
      </div>
      <PvButton label="COMPRAR AHORA" class="w-full" @click="navigateTo(`/store/${productCode}`)" />
    </div>
  </CardComponent>
</template>
