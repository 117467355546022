<script lang="ts" setup>
import CardComponent from '~/components/CardComponent.vue'

const { highligths } = useContent()
</script>

<template>
  <div>
    <TitleComponent text="Nuestra promesa." tagline="Más que un seguro, una experiencia" />
    <div class="flex flex-wrap justify-center gap-12">
      <div class="grid grid-cols-2 items-stretch gap-2 md:grid-cols-3 md:gap-4 lg:grid-cols-6">
        <template v-for="highligth in highligths" :key="highligth.code">
          <CardComponent bg="ligth">
            <template #header>
              <span class="text-sm font-bold uppercase md:text-lg">{{ highligth.title }}</span>
            </template>

            <div class="p-3">
              <div class="flex w-full justify-center">
                <img :src="highligth.icon" :alt="highligth.title" class="size-14 md:size-16" />
              </div>
              <div class="text-center text-[14px]">
                {{ highligth.description }}
              </div>
            </div>
          </CardComponent>
        </template>
      </div>
    </div>
  </div>
</template>
