<script setup lang="ts">
import { StoreProcessType, type ProductCode } from '~/config'

const props = defineProps<{
  productCode: ProductCode
}>()

const emits = defineEmits(['close'])
const { products, companies, contact } = useContent()
const product = computed(() => products.value.find((p) => props.productCode === p.code))

const avaliableCompanies = computed(
  () => companies.value.filter((c) => product.value?.insurances.includes(c.code)) ?? []
)
</script>

<template>
  <div
    class="relative flex aspect-[16/8] w-full items-end bg-cover bg-center md:aspect-[16/6]"
    :style="{ 'background-image': `url(${product?.bannerUrl})` }"
  >
    <div class="absolute right-0 top-0 z-10">
      <PvButton class="place-self-end self-start" text @click="emits('close')">
        <span class="icon-[ph--x-circle] text-4xl text-white"></span>
      </PvButton>
    </div>
    <div class="absolute z-0 h-full w-full bg-primary-950/50" />
    <div class="z-1 relative flex flex-col gap-2 p-4 md:p-8">
      <div class="flex items-center gap-4">
        <img :src="product?.iconUrl" :alt="product?.name" class="size-10 rounded-md md:size-20" />
        <span class="text-surface text-3xl font-bold uppercase md:text-5xl">
          {{ product?.name }}
        </span>
      </div>
      <div class="flex gap-2">
        <PvButton @click="navigateTo(`/store/${productCode}`)">
          <span>{{ product?.process === StoreProcessType.QUOTE ? 'Cotizar' : 'Comprar' }}</span>
        </PvButton>

        <PvButton
          plain
          as="a"
          class="whatsapp bg-[#009688]"
          :href="contact.others.find((item) => item.text === 'WhatsApp')?.href"
          target="_blank"
        >
          <div class="flex items-center gap-2">
            <span class="icon-[mdi--whatsapp] text-xl"></span>
            <span class="hidden md:inline">Chatea con un agente</span>
          </div>
        </PvButton>
      </div>
    </div>
  </div>
  <div class="grid gap-8 p-4 md:grid-cols-3 md:p-8">
    <div class="flex flex-col gap-4 md:col-span-2">
      <TitleComponent v-if="product?.tagline" :text="product?.tagline" type="subtitle" />
      {{ product?.description }}
      <template v-if="product?.process === StoreProcessType.PURCHASE && !product?.plans?.length">
        <PvButton class="w-full" @click="navigateTo(`/store/${productCode}`)">
          <span class="py-2 text-xl font-bold uppercase md:text-2xl">Comprar ahora</span>
        </PvButton>
      </template>
      <div class="grid grid-cols-2 gap-2 lg:grid-cols-4 lg:gap-4">
        <template v-for="(h, i) in product?.highlights" :key="i">
          <CardComponent v-tooltip.bottom="h.description" bg="ligth">
            <template #header>
              <span class="text-xs font-bold uppercase">{{ h.title }}</span>
            </template>

            <div class="p-3">
              <div class="flex w-full justify-center">
                <img :src="h.artWork" :alt="h.title" class="md:h-18 my-2 h-16" />
              </div>
            </div>
          </CardComponent>
        </template>
      </div>
    </div>
    <div class="flex flex-col justify-end gap-2">
      <div>Respaldado por:</div>
      <div class="grid grid-cols-2 gap-2">
        <template v-for="company in avaliableCompanies" :key="company.code">
          <CardComponent bg="ligth">
            <img :src="company.logoWhiteUrl" class="rounded-md" />
          </CardComponent>
        </template>
      </div>
    </div>
    <div
      v-if="product?.process !== StoreProcessType.PURCHASE || product?.plans?.length"
      class="md:col-span-3"
    >
      <template v-if="product?.process === StoreProcessType.PURCHASE && !!product?.plans?.length">
        <div class="grid gap-4 md:grid-cols-3">
          <template v-for="plan in product?.plans" :key="plan.code">
            <ContentProductsPlan :product-code="productCode" :plan="plan" />
          </template>
        </div>
      </template>
      <template v-else-if="product?.process === StoreProcessType.QUOTE">
        <CardComponent bg="ligth">
          <div class="grid gap-4 p-4 text-center">
            <span class="text-md uppercase md:text-2xl">
              Recibe tus cotizaciones en menos de 24 horas.
            </span>
            <PvButton class="w-full" @click="navigateTo(`/store/${productCode}`)">
              <span class="py-2 text-xl font-bold uppercase md:text-2xl">Cotiza ahora</span>
            </PvButton>
          </div>
        </CardComponent>
      </template>
    </div>
    <div class="flex flex-col gap-4 md:col-span-2">
      <ListComponent
        v-if="product?.compliances.length"
        :items="product?.compliances"
        title="Qué cubre"
        icon="ph--check-circle-bold"
      />
      <div v-if="product?.compliances.length && product?.exceptions.length">
        <PvDivider />
      </div>
      <ListComponent
        v-if="product?.exceptions.length"
        :items="product?.exceptions"
        title="Qué no cubre"
        icon="ph--x-circle-bold"
      />
    </div>
    <div class="md:hidden">
      <PvDivider />
    </div>
    <div>
      <ListComponent
        v-if="product?.requirements.length"
        :items="product?.requirements"
        title="Requisitos"
        icon="ph--info-bold"
      />
    </div>
  </div>
</template>
