<script setup lang="ts">
//const viewport = useViewport()

//const isMovile = computed(() => viewport?.breakpoint.value === 'sm')
//const products = ['auto', 'viajes']

//const bannerImgs = computed(() =>
//  products.map((p) => ({
//    src: `/img/banners/banner_${p + (isMovile.value ? '_movile' : '')}.png`,
//    alt: `${p}`,
//  }))
//)

const bannerImg = ref<string[]>(['autoBanner'])
const { allCompleted } = useImageLoaderTracker(bannerImg)
</script>

<template>
  <div>
    <PvSkeleton v-show="!allCompleted" class="aspect-[5665/2452] w-full" />
    <div v-show="allCompleted">
      <div class="sm:p-3 lg:p-5" @click="navigateTo('/store/auto')">
        <template v-if="$viewport.match('sm')">
          <img id="autoBanner" src="/img/banners/banner_auto_movile.png" class="w-full rounded" />
        </template>
        <template v-else>
          <img id="autoBanner" src="/img/banners/banner_auto.png" class="w-full rounded" />
        </template>
      </div>

      <!-- <PvCarousel
          v-show="false"
          :value="imgs"
          :numVisible="1"
          :numScroll="1"
          circular
          :autoplayInterval="5000"
          :showNavigators="false"
        >
          <template #item="slotProps">
            <img
              :src="slotProps.data.src"
              :alt="slotProps.data.alt"
              class="w-full rounded"
              ref="bannerImg"
            />
          </template>
        </PvCarousel> -->
    </div>
  </div>
</template>
