<script lang="ts" setup>
const { companies } = useContent()

const companyLogos = ref<string[]>(companies.value.map((c) => `${c.code}Logo`))
const { allCompleted } = useImageLoaderTracker(companyLogos)
</script>

<template>
  <div>
    <TitleComponent text="Aseguradoras." tagline="confianza y respaldo de marcas líderes" />

    <div
      class="grid grid-cols-2 flex-wrap justify-center gap-2 sm:grid-cols-3 md:grid-cols-6 md:gap-4"
    >
      <template v-for="company in companies" :key="company.code">
        <PvSkeleton v-show="!allCompleted" class="aspect-[17/9] w-full" />
        <CardComponent v-show="allCompleted" bg="ligth">
          <img
            :id="`${company.code}Logo`"
            :src="company.logoWhiteUrl"
            class="aspect-[17/9] rounded-md object-cover"
          />
        </CardComponent>
      </template>
    </div>
  </div>
</template>
